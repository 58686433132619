import { useNavigate } from "react-router-dom";

const Home2OurBlog = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden petal-back" >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 image-grid">
            <img src={'https://petalsofapeony.com/static/media/pop3.db3442603d3541c72e23.png'} alt="Fresh Lemon Tea" />
            <img src={'https://petalsofapeony.com/static/media/pop2.5085d3b5b9ea6c25c283.png'} alt="Fresh Lemon Tea" />
            <img src={'https://petalsofapeony.com/static/media/pop4.32dcf55ac93d41129b8b.png'} alt="Fresh Lemon Tea" />
            <img src={'https://petalsofapeony.com/static/media/pop5.2343913e8dbe05a848ad.png'} alt="Fresh Lemon Tea" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp text-content">
            <h2 className="title text-center">Welcome to Yummy Spicy- Doraville</h2>
            <p className="text-black">
            Yummy Spicy Gourmet Restaurant offers authentic and delicious tasting Chinese cuisine in Doraville, GA.  Yummy Spicy's convenient location and affordable prices make our restaurant a natural choice for dine-in or take-out meals in the Doraville community. Our restaurant is known for its variety in taste and high quality fresh ingredients. Come and experience our friendly atmosphere and excellent service.
            </p>
            <button onClick={() => navigate('/online-order')} className="btn btn-primary w-100 p-3" >Order Online</button> 
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
