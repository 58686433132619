const ContactSection = () => {
  return (
    <section className="contact-section d-flex h-100">
      <div className="container">
        <div className="row h-100">
          <div className="col-lg-6 ">
            <img 
              src="https://petalsofapeony.com/static/media/pop11.80e70fa6fee0eb12e0a2.jpg" 
              alt="Restaurant Dish" 
              className="img-fluid w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="col-lg-6 d-flex flex-column  p-5">
            <h2 className="title text-center mb-4">SEE YOU SOON</h2>
            <p className="contact-info">
              <strong>PHONE NUMBER</strong><br />
              (770) 680-5605
            </p>
            {/* <p className="contact-info">
              <strong>EMAIL ADDRESS</strong><br />
              petalsofapeony65@gmail.com
            </p> */}
            <p className="contact-info">
              <strong>RESTAURANT</strong><br />
              5164 Buford Hwy NE, Doraville, GA 30340
            </p>
            <p className="mt-4 text-center text-orange">
              WE TRY TO MAKE OUR RESTAURANT AS AUTHENTIC AS POSSIBLE, SO OUR CUSTOMERS WILL HAVE THE SAME EXPERIENCE LIKE EATING IN THE FINEST SICHUAN RESTAURANT IN CHINA WITHOUT STEPPING ON A PLANE.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
