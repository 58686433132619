import { useNavigate } from "react-router-dom"
import { FaFacebook, FaInstagram } from "react-icons/fa"

const MainBanner2 = () => {
  const navigate = useNavigate()

  const backgroundImageUrl = "https://koala.sh/api/image/v2-a9xsq-2n1fc.jpg?width=1344&height=768&dream"

  return (
    <div 
      className="main-bnr-three overflow-hidden top-space"
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "80vh",
        position: "relative"
      }}
    >
      <div className="top-banner-text text-center">
        <span className="petals-text text-white">
       
        Yummy Spicy
        </span>
      </div>
      <div className="center-content d-flex flex-column justify-content-center align-items-center text-center">
        <h2 className="bnr-text text-danger">
        辣时代

        </h2>
        <div className="banner-btn d-flex justify-content-center align-items-center ">
          <div
            onClick={() => navigate("/online-order")}
            className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
          >
            <span>Order Online</span>
          </div>
          <div
            onClick={() => navigate("/menu")}
            className="btn btn-dark btn-md shadow-primary btn-hover-1"
          >
            <span>View Menu</span>
          </div>
        </div>
      </div>
      {/* <div className="social-icons d-flex justify-content-center align-items-center">
        <a href="https://www.instagram.com/petalsofapeony/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FaInstagram size={80} color="white" />
        </a>
        <a href="https://www.facebook.com/PetalsofaPeony/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FaFacebook size={80} color="white" />
        </a>
      </div> */}
    </div>
  )
}

export default MainBanner2
